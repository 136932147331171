<template>
  <div class="wrapper--compare" :class="minim ? 'minim' : ''">
    <div class="wrapper--button-pop position-absolute w-100 u--center px-2" v-if="minim">
      <button class="btn btn--cta w-100" @click="minimize" type="button">
        {{ $t('general.compare.open') }}
      </button>
    </div>
    <transition
      name="map-popup"
      enter-active-class="animated slideInUp"
      leave-active-class="animated slideOutDown"
    >
      <div class="bg-white wrapper--content py-2 pa-md-4" v-if="compareShow">
        <div class="d-flex justify-space-between align-center container">
          <div class="wrapper--submain">
            <div class="title--text">{{ $t('general.compare.message') }}</div>
            <!--            <div class="subtitle&#45;&#45;text">{{ $t('general.compare.maks') }}</div>-->
          </div>
          <div class="wrapper--image d-flex">
            <div class="item--image mr-3" v-for="listing in listings" :key="listing.key">
              <div class="wrapper--button" @click="close(listing)">
                <img src="@/assets/img/icons/close-2.svg" />
              </div>
              <div style="width: 100%; height: 100%" v-if="listing && listing.primary_photo">
                <picture>
                  <source :srcset="listing.primary_photo.url" type="image/webp" />
                  <source :srcset="getFallbackImage(listing.primary_photo.url)" type="image/jpeg" />
                  <img
                    :src="getFallbackImage(listing.primary_photo.url)"
                    class="listing--image"
                    :alt="listing.listing_title"
                  />
                </picture>
              </div>
              <img
                v-else
                class="listing--image"
                src="/img/fallback_image.jpg"
                :alt="listing.listing_title"
              />
            </div>
          </div>
          <div class="wrapper--button wrapper--submain">
            <button
              :disabled="listings.length > 1 ? false : true"
              class="btn btn-primary w-100 mb-1"
              @click="showModal()"
              id="btn-comparation"
              type="button"
            >
              {{ $t('general.compare.compare') }}
            </button>
            <button class="btn btn-primary--outline w-100 mb-1" @click="minimize" type="button">
              {{ $t('general.compare.close') }}
            </button>
            <button class="btn btn-light w-100" @click="closeCompare" type="button">
              {{ $t('general.compare.delete') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'compare',
  mixins: [HelperMixin],
  data: () => ({
    minim: false,
  }),
  computed: {
    ...mapState({
      listings: (state) => state.v2.listing.compareListings,
    }),
    compareShow() {
      return this.$store.getters['v2/listing/compareShow'];
    },
  },
  methods: {
    closeCompare() {
      this.$store.commit('v2/listing/reset_compare_listing');
      this.$store.commit('v2/listing/set_compare_show', false);
    },
    close(listing) {
      this.$store.commit('v2/listing/remove_compare_listing', listing);
    },
    showModal() {
      this.$modal.show('modal-compare');
      this.$store.commit('v2/listing/set_compare_show', false);
    },
    minimize() {
      this.minim = !this.minim;
    },
  },
};
</script>

<style scoped></style>
